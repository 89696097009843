<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#9B735A;"
      d="M357.517,35.31H233.931c0,9.75-7.905,17.655-17.655,17.655c-9.75,0-17.655-7.905-17.655-17.655
	H75.034c-14.626,0-26.483,11.857-26.483,26.483v370.759c0,14.626,11.857,26.483,26.483,26.483h282.483
	c14.626,0,26.483-11.857,26.483-26.483V61.793C384,47.167,372.143,35.31,357.517,35.31z"
    />
    <path
      style="fill:#8C5F50;"
      d="M375.172,317.793c-2.98,0-5.909,0.204-8.828,0.446V61.793c0-4.875-3.953-8.828-8.828-8.828h-44.138
	c-4.875,0-8.828-3.953-8.828-8.828V35.31h-70.621c0,9.75-7.905,17.655-17.655,17.655c-9.75,0-17.655-7.905-17.655-17.655H128v8.828
	c0,4.875-3.953,8.828-8.828,8.828H75.034c-4.875,0-8.828,3.953-8.828,8.828v370.759c0,4.875,3.953,8.828,8.828,8.828h195.75
	c1.05,6.07,2.602,11.97,4.615,17.655h82.119c14.626,0,26.483-11.857,26.483-26.483V318.239
	C381.081,317.997,378.153,317.793,375.172,317.793z"
    />
    <path
      style="fill:#F1F4FB;"
      d="M339.862,423.724H92.69c-4.875,0-8.828-3.953-8.828-8.828V79.448c0-4.875,3.953-8.828,8.828-8.828
	h247.172c4.875,0,8.828,3.953,8.828,8.828v335.448C348.69,419.772,344.737,423.724,339.862,423.724z"
    />
    <path
      style="fill:#E4EAF8;"
      d="M348.69,414.897v-93.632c-45.664,11.776-79.448,53.117-79.448,102.46h70.621
	C344.737,423.724,348.69,419.772,348.69,414.897z"
    />
    <path
      style="fill:#FFF082;"
      d="M269.241,17.655h-22.552C240.573,7.145,229.312,0,216.276,0s-24.297,7.145-30.413,17.655H163.31
	c-9.75,0-17.655,7.904-17.655,17.655v35.31c0,4.875,3.953,8.828,8.828,8.828h123.586c4.875,0,8.828-3.953,8.828-8.828V35.31
	C286.897,25.559,278.991,17.655,269.241,17.655z M216.276,48.552c-7.313,0-13.241-5.929-13.241-13.241s5.929-13.241,13.241-13.241
	s13.241,5.929,13.241,13.241S223.588,48.552,216.276,48.552z"
    />
    <path
      style="fill:#FFDC64;"
      d="M154.483,79.448h123.586c4.875,0,8.828-3.953,8.828-8.828l0,0c0-4.875-3.953-8.828-8.828-8.828
	H154.483c-4.875,0-8.828,3.953-8.828,8.828l0,0C145.655,75.496,149.608,79.448,154.483,79.448z"
    />
    <circle style="fill:#B4E66E;" cx="375.172" cy="423.724" r="88.276" />
    <path
      style="fill:#A0D755;"
      d="M419.31,494.345c-48.754,0-88.276-39.522-88.276-88.276c0-28.622,13.683-53.987,34.797-70.116
	c-44.36,4.668-78.935,42.174-78.935,87.772c0,48.754,39.522,88.276,88.276,88.276c20.131,0,38.627-6.815,53.479-18.159
	C425.58,494.164,422.466,494.345,419.31,494.345z"
    />
    <path
      style="fill:#FFFFFF;"
      d="M357.517,467.862c-2.259,0-4.518-0.862-6.241-2.586l-35.31-35.31c-3.448-3.448-3.448-9.035,0-12.483
	c3.448-3.448,9.035-3.448,12.483,0l29.068,29.07L413.069,391c3.448-3.448,9.035-3.448,12.483,0s3.448,9.035,0,12.483l-61.793,61.793
	C362.035,467,359.776,467.862,357.517,467.862z"
    />
    <g>
      <path
        style="fill:#A0D755;"
        d="M180.966,174.357v-32.258c10.952,2.036,17.655,7.501,17.655,11.556c0,4.879,3.948,8.828,8.828,8.828
		s8.828-3.948,8.828-8.828c0-14.795-14.932-26.766-35.31-29.481v-0.588c0-4.879-3.948-8.828-8.828-8.828
		c-4.879,0-8.828,3.948-8.828,8.828v0.588C142.932,126.889,128,138.86,128,153.655c0,20.945,19.346,29.039,35.31,33.919v32.258
		c-10.952-2.036-17.655-7.501-17.655-11.556c0-4.879-3.948-8.828-8.828-8.828c-4.879,0-8.828,3.948-8.828,8.828
		c0,14.795,14.932,26.766,35.31,29.481v0.588c0,4.879,3.948,8.828,8.828,8.828c4.879,0,8.828-3.948,8.828-8.828v-0.588
		c20.378-2.714,35.31-14.686,35.31-29.481C216.276,187.331,196.93,179.236,180.966,174.357z M145.655,153.655
		c0-4.055,6.703-9.521,17.655-11.556v26.792C150.303,164.252,145.655,160.042,145.655,153.655z M180.966,219.832v-26.792
		c13.007,4.639,17.655,8.849,17.655,15.235C198.621,212.331,191.917,217.796,180.966,219.832z"
      />
      <path
        style="fill:#A0D755;"
        d="M295.724,150.069h-44.138c-4.879,0-8.828-3.948-8.828-8.828s3.948-8.828,8.828-8.828h44.138
		c4.879,0,8.828,3.948,8.828,8.828S300.604,150.069,295.724,150.069z"
      />
    </g>
    <g>
      <path
        style="fill:#959CB5;"
        d="M278.069,185.379h-26.483c-4.879,0-8.828-3.948-8.828-8.828c0-4.879,3.948-8.828,8.828-8.828h26.483
		c4.879,0,8.828,3.948,8.828,8.828C286.897,181.431,282.948,185.379,278.069,185.379z"
      />
      <path
        style="fill:#959CB5;"
        d="M295.724,220.69h-44.138c-4.879,0-8.828-3.948-8.828-8.828c0-4.879,3.948-8.828,8.828-8.828h44.138
		c4.879,0,8.828,3.948,8.828,8.828C304.552,216.742,300.604,220.69,295.724,220.69z"
      />
    </g>
    <g>
      <path
        style="fill:#B4E66E;"
        d="M154.483,300.138h-17.655c-4.879,0-8.828-3.948-8.828-8.828c0-4.879,3.948-8.828,8.828-8.828h17.655
		c4.879,0,8.828,3.948,8.828,8.828C163.31,296.19,159.362,300.138,154.483,300.138z"
      />
      <path
        style="fill:#B4E66E;"
        d="M154.483,370.759h-17.655c-4.879,0-8.828-3.948-8.828-8.828s3.948-8.828,8.828-8.828h17.655
		c4.879,0,8.828,3.948,8.828,8.828S159.362,370.759,154.483,370.759z"
      />
      <path
        style="fill:#B4E66E;"
        d="M154.483,335.448h-17.655c-4.879,0-8.828-3.948-8.828-8.828c0-4.879,3.948-8.828,8.828-8.828h17.655
		c4.879,0,8.828,3.948,8.828,8.828C163.31,331.5,159.362,335.448,154.483,335.448z"
      />
    </g>
    <g>
      <path
        style="fill:#959CB5;"
        d="M295.724,300.138H189.793c-4.879,0-8.828-3.948-8.828-8.828c0-4.879,3.948-8.828,8.828-8.828
		h105.931c4.879,0,8.828,3.948,8.828,8.828C304.552,296.19,300.604,300.138,295.724,300.138z"
      />
      <path
        style="fill:#959CB5;"
        d="M269.241,370.759h-79.448c-4.879,0-8.828-3.948-8.828-8.828s3.948-8.828,8.828-8.828h79.448
		c4.879,0,8.828,3.948,8.828,8.828S274.121,370.759,269.241,370.759z"
      />
      <path
        style="fill:#959CB5;"
        d="M295.724,335.448H189.793c-4.879,0-8.828-3.948-8.828-8.828c0-4.879,3.948-8.828,8.828-8.828
		h105.931c4.879,0,8.828,3.948,8.828,8.828C304.552,331.5,300.604,335.448,295.724,335.448z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
